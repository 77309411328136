@import "../../styles/__imports.scss";

.image-box {
    width: calc(25% - 10px);
    height: auto;
    margin: 5px;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &::before {
        content: "";
        display: block;
        padding-top: 65%;
    }

    &:hover,
    &:focus,
    &:active {
        img {
            transform: scale(1.05);
        }
    }

    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: transform 0.2s ease-out;
    }

    &__button {
        @include clearButton();
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: 30px;
        text-shadow: 0 0 8px $color__omega;
        color: $color__alfa;
        z-index: 200;
        transition: color 0.2s ease-out;

        &:hover,
        &:focus,
        &:active {
            color: $color__alfa-alt;
        }
    }
}
