@import "../../styles/__imports.scss";

.row {
    &-header {
        padding: 6px 0;
        background-color: $color__alfa !important;
        color: $color__omega;
        font-weight: 600;
    }

    &-data {
        padding: 6px 0;

        &:nth-child(odd) {
            background-color: rgba($color: $color__alfa-alt, $alpha: 0.2) !important;
            color: $color__psi;
        }
    }

    &-sum {
        padding: 6px 0;
        background: rgba($color: $color__alfa-alt, $alpha: 0.4) !important;
        color: $color__psi;
        font-weight: 500;
        border: 1px solid $color__alfa;
    }
}

.row {
    &.row-sum {
        background: rgba($color: $color__alfa-alt, $alpha: 0.4) !important;
    }

    &.row-header {
        background-color: $color__alfa !important;
    }

    &.row-data {
        &:nth-child(odd) {
            background-color: rgba($color: $color__alfa-alt, $alpha: 0.2) !important;
            color: $color__psi;
        }
    }
}
