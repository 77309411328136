@import "../../styles/__imports.scss";

.btn {
    &__delete {
        @include clearButton();
        padding-left: 20px;
        color: $color__alfa;
        font-size: 20px;
        transition: color 0.2s ease-out;

        &:hover,
        &:focus,
        &:active {
            color: $color__alfa-alt;
        }
    }
}
