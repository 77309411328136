@import "../../styles/__imports.scss";

.confirm {
    width: 60vw;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 25px 20px;
    z-index: 1000;
    background-color: $color__omega;
    box-shadow: 3px 3px 10px $color__shadow;

    &__message {
        margin-bottom: 20px;
    }
}
